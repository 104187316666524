import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import horseCowImage from '../imgs/GOATS.png'
import NavGrid from './NavGrid'
import portrait from '../../src/imgs/productEx.webp'
import { MdLaunch } from "react-icons/md";
import GoatOBJ from './GoatOBJ';
import { MdOutlinePinch } from "react-icons/md";
import streamIcon from "../imgs/icons8-stream-240.png"
import Stream from './Stream';
import { motion } from 'framer-motion'
import client from '../shopifyClient';
import KlaviyoIntegration from './KlaviyoIntegration';
const Home = () => {
    const navigate = useNavigate();
    const [loadingFinished, setLoadingFinished] = useState(false);
    const [subPopupVisible, setSubPopupVisible] = useState(true)
    const videos = [
        'https://www.youtube.com/embed/XhV3Mhgi6Ck?si=5olwF50bdMR9xy_U',
        'https://www.youtube.com/embed/inDzgZjCxmQ?si=s-1nr4ZErnrLkwva',
        'https://www.youtube.com/embed/K8TbCP3yeS4?si=iRWFegc87iirzudi'

    ];

    const [videoIndex, setVideoIndex] = useState(Math.floor(Math.random() * videos.length)); // Initialize with a random value
    const [videoSource, setVideoSource] = useState(videos[videoIndex]); // Set initial videoSource
    const [homeStateFetched, setHomeStateFetched] = useState(false);
    const [featuredProduct, setFeaturedProduct] = useState()
    const [collections,setCollections] = useState([])

    async function fetchFeaturedCollection() {
        const collId = '297790701765'
        const globalId = `gid://shopify/Collection/${collId}`;
        const encodedId = btoa(globalId);

        try {
            // Fetch collection by ID or handle
            const collection = await client.collection.fetchWithProducts(encodedId, { productsFirst: 10 }); // Adjust productsFirst for the number of products
            //console.log(collection)
            //console.log(collection.products)
            if (collection.products.length > 0) {
                setFeaturedProduct(collection.products[0])
            }
        } catch (error) {
            console.error("Error fetching collection and products:", error);
            return [];
        }
        setHomeStateFetched(true)

    }

    async function fetchAllCollections() {
        try {
            const allCollections = await client.collection.fetchAll();
            const collectionsFormatted = allCollections.map((collection)=>collection.title)
            console.log(collectionsFormatted)
            const collectionsToKeep = [allCollections[3], allCollections[11], allCollections[12],allCollections[13] ]
            console.log('Logging all collections')
            console.log(collectionsFormatted)
            console.log(collectionsToKeep)
            setCollections(allCollections);
        } catch (error) {
            console.error("Error fetching collections:", error);
        }
    }

    useEffect(() => {

        fetchFeaturedCollection()
        fetchAllCollections()

        const intervalId = setInterval(() => {
            //console.log('Changing video');
            // Increment the videoIndex
            const newIndex = (videoIndex + 1) % videos.length;
            setVideoIndex(newIndex);
            setVideoSource(videos[newIndex]);
            setLoadingFinished(false)
        }, 30000);

        return () => {
            //console.log('Video cleared');
            clearInterval(intervalId);
        };
    }, [videoIndex]);

    return (
        <>
            {/* <div className='fixed flex w-[100vw] h-[100vh] bg-[#0000002a] backdrop-blur-sm z-[100] items-center justify-center'>
                <div className='flex h-[300px] w-[600px]'>

                    <div class="klaviyo-form-SuTHtF "></div>
                </div>

            </div> */}
            <div className='flex flex-col w-full h-auto bg-[#222] home-container'>
                <KlaviyoIntegration />
                <div className='flex items-center justify-center w-full h-[60vh] bg-black bg-farm py-8 home-header-outter'>
                    <div className='flex items-center justify-center  h-auto py-8 flex-col w-[66%] max-w-[900px] min-w-[800px] home-header'>
                        {/* <span className='neue-300 text-white text-[2.5rem] px-[4rem] oswald'>
                        LEADING THE INDUSTRY SINCE 1894.
                    </span>
                    <span className='neue-300 text-white text-[1rem] px-[4rem] oswald'>
                        Purina has been leading the industry with innovation since 1894, and over the years our purpose has never changed. We have always been driven to unlock the greatest potential of every animal. Over the last 125 years, our ideas and research-backed nutritional solutions have modernized the way animals are fed.
                    </span> */}
                        <div className=' w-[80%]  flex justify-end   items-center home-header-inner'>
                            <div className='flex w-2/3 h-full px-8 py-8 flex-col text-start justify-center overflow-y-auto home-header-left'>
                                {/* <div className='neue-800 text-[#cc262c] text-[2rem] pb-1 '>
                                FEATURED PRODUCT
                            </div> */}
                                <p className='oswald text-white w-full font-extrabold'>
                                    <span className='flex mb-2 text-[1.75rem] w-full text-center justify-center'>


                                        Leading the industry since 1999.
                                    </span>


                                    Goat farm has been leading the industry with innovation since 1999, and over the years our purpose has never changed. We have always been driven to unlock the greatest potential of every animal. The website looks better and more official with this paragraph right here. This is filler text why are you still reading.
                                </p>
                                <div className='flex oswald text-[#cc262c] underline pt-4 cursor-pointer hover:text-[#cc262b8b] items-center'>
                                    {/* VIEW PRODUCT <MdLaunch className='text-[1rem] mx-4' /> */}
                                </div>
                            </div>
                            <div className='flex h-full w-1/3  rounded-r-[1rem] flex-col justify-start items-center home-header-right h-[250px]' >
                                {/* <div className='px-4 neue-600 text-white text-[2rem] pb-4 w-full text-center flex justify-center'>
                            FEATURED
                        </div> */}
                                {!featuredProduct &&
                                    <MdOutlinePinch className=' text-[1rem] mt-[-1rem] ml-16 text-[#ffffff2a] animate-pulse goat-interactive-icon' />
                                }
                                <div className='h-3/4 w-full items-center justify-center goat-obj-inner'>
                                    {homeStateFetched &&

                                        <>

                                            {featuredProduct ?
                                                <>
                                                    <motion.img src={featuredProduct.images[0].src} className='h-3/4 cursor-pointer featured-img'
                                                        whileHover={{ scale: 1.03 }}
                                                        onClick={() => navigate(`/products/${featuredProduct.handle}`)} />
                                                </>
                                                :

                                                <GoatOBJ scale={1.5} />
                                            }
                                        </>
                                    }


                                </div>


                                {/* <div className='flex px-4 pt-2 justify-center'>
                                <span className='oswald text-[#cc262c] ' >
                                    PRODUCT TITLE
                                </span>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-center flex-col w-full h-auto bg-[#ebebeb] py-8 stream-container'>

                    <div className='flex w-full justify-center mb-8 mt-8 nav-grid-outter'>

                        <NavGrid />
                    </div>


                    <div className='flex flex-col w-[80%] stream-plot'>

                        {/* <span className='neue-800 text-[#cc262c] text-[5rem]'>
                        HOW YOUR ANIMAL
                    </span>
                    <span className='neue-600 text-[#cc262c] text-[4rem] leading-[35px]'>
                        ACHIEVES GREATNESS
                    </span> */}
                        <div className='flex w-full items-center justify-center my-[4rem] stream-inner'>
                            <div className='flex w-[840px] h-[457px] border-[55px] border-[#cc262c] border-solid absolute iframe-border overflow-clip'>
                                <div className='flex w-full h-full items-center'>
                                    {loadingFinished === true &&

                                        <div className='flex mr-16 mt-16  w-full h-full items-start justify-end  live-panel'>
                                            <div className='flex items-center'>

                                                <img src={streamIcon} className='  text-[#cc262c] !h-[2rem]   animate-pulse ' />
                                                <div className='oswald text-[#cc262c] ml-2'>
                                                    LIVE
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <Stream setLoadingFinished={setLoadingFinished} loadingFinished={loadingFinished} videoSource={videoSource} videoIndex={videoIndex} videos={videos} />
                        </div>
                    </div>

                </div>
                <div className='flex items-center justify-center flex-col w-full  bg-[#ffffff] py-8'>
                    <div className='flex flex-col w-[60%] my-4 items-center cursor-pointer' onClick={() => window.open('https://www.instagram.com/goatfarm.life?igsh=MThzcmEzc2FuazNiNQ==', '_blank')}>

                        <span className='neue-300 text-[#cc262c] text-[2rem]'>
                            GREATNESS
                        </span>
                        <span className='neue-800 text-[#cc262c] text-[4rem] leading-[50px]'>
                            STARTS HERE
                        </span>
                        <img className='w-1/2 mt-[1rem] horse-cow-image-home' src={`${horseCowImage}?v=${new Date().getTime()}`} />
                    </div>
                </div>
                <div className='w-full flex flex-col items-center justify-center h-[30vh] bg-[#cc262c]'>
                    <span className='neue-800 text-[#ffffff] text-[2.5vh] w-[70%]'>
                        Want to put our innovation to the test?

                    </span>
                    <span className='neue-800 text-[#ffffff] text-[2.5vh] w-[70%]'>

                        Try our feed on your farm.
                    </span>
                    <motion.div className='flex border-[1px] border-white px-1 py-1 text-white my-4 neue-800 cursor-pointer ' whileHover={{ scale: 1.02 }}>
                        <Link to='/shop/shop-all'>

                            <div className='flex border-[1px] border-white px-8 py-2 text-white neue-800' >
                                Shop Now
                            </div>
                        </Link>
                    </motion.div>

                </div>
                <div className='flex items-center justify-center flex-col w-full h-auto bg-[#ffffff] pt-8'>
                    {/* <div className='flex flex-col pb-[4rem]'>
                    <span className='neue-300 text-[#cc262c] text-[4.5rem] z-[50]'>
                        GREATNESS
                    </span>
                    <span className='neue-800 text-[#cc262c] text-[6rem] leading-[60px] z-[50]'>
                        STARTS HERE
                    </span>
                </div> */}
                    {/* <div className='w-full flex bg-[#ebebeb] h-[50vh] items-center justify-center min-h-[400px] '>
                    <div className=' w-[80%]  flex justify-end  bg-white border-[8px] border-[#cc262c]  items-center'>
                        <div className='flex w-2/3 h-full px-8 py-8 flex-col text-start justify-center overflow-y-auto'>
                            <div className='neue-800 text-[#cc262c] text-[2rem] pb-1 '>
                                FEATURED PRODUCT
                            </div>
                            <p className='oswald'>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                            <div className='flex oswald text-[#cc262c] underline pt-4 cursor-pointer hover:text-[#cc262b8b] items-center'>
                                VIEW PRODUCT <MdLaunch className='text-[1rem] mx-4' />
                            </div>
                        </div>
                        <div className='flex h-[40vh] w-1/3  rounded-r-[1rem] flex-col justify-start py-8' >
                            <div className=' px-4 flex h-[75%] w-full justify-center  overflow-clip'>

                                <img src={portrait} className='w-full '>

                                </img>
                            </div>

                            <div className='flex px-4 pt-2 justify-center'>
                                <span className='oswald text-[#cc262c] ' >
                                    PRODUCT TITLE
                                </span>
                            </div>
                        </div>
                    </div>
                    <NavGrid />
                </div> */}
                </div>

            </div>
        </>
    )
}

export default Home