import React from 'react'
import portrait from '../imgs/portrait.png'
import horseCowImage from '../imgs/DwarfNew.png'
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { IoLogoDiscord } from "react-icons/io5";
import cnLogo from '../imgs/sponsorships/CARTOON_NETWORK_logo.png'
import mcdsLogo from '../imgs/sponsorships/McDonalds-Logo-1968.png'
import stillzLogo from '../imgs/sponsorships/green_logo.webp'
import swLogo from '../imgs/sponsorships/sw-saintwoods_logo-black.webp'
import wahineLogo from '../imgs/sponsorships/wahine-logo.svg'
import { FaLink } from "react-icons/fa";
import { motion } from 'framer-motion'
const About = () => {
    const launchLink = async (url) => {
        setTimeout(() => {
            window.open(url, '_blank').focus();
        })
    }
    const gridItems = [
        {
            link: 'https://www.cartoonnetwork.com/index.html',
            img: cnLogo
        },
        {
            link: 'https://kerwinfrost.com/',
            img: mcdsLogo
        },
        {
            link: 'https://www.staystillz.com/',
            img: stillzLogo
        },
        {
            link: 'https://saintwoods.com/',
            img: swLogo
        },
        {
            link: 'https://wahine.shop/',
            img: wahineLogo
        }
    ];
    return (
        <>
            <div className='flex w-full bg-[#ffffff] justify-center h-auto items-center flex-col about-outer min-h-[90vh]'>
                {/* <div className='flex justify-center w-[80%]'>
                    <div className='flex flex-col w-[100%] my-4 items-center cursor-pointer' onClick={() => window.open('https://www.instagram.com/goatfarm.life?igsh=MThzcmEzc2FuazNiNQ==', '_blank')}>

                        <span className='neue-300 text-[#cc262c] text-[2rem]'>
                        GREATNESS
                        </span>
                        <span className='neue-800 text-[#cc262c] text-[4rem] leading-[50px]'>
                            STARTS HERE
                        </span>
                        <img className='w-[300px] mt-[1rem]' src={horseCowImage} />
                    </div>
                    <img className='w-1/2 mt-[1rem]' src={horseCowImage} />

                </div> */}

                <div className='w-[100%] bg-[#efefef]   py-16 justify-center flex border-y-[16px] border-[#cc262c] about-container'>
                    <div className='flex w-[80%] justify-center about-shell'>


                        <img src={portrait} className='h-[60vh] w-auto portrait' />

                        <div className='flex w-1/2 h-full px-8 flex-col text-start justify-start about-right'>
                            <div className='flex flex-col'>

                                {/* <div className='neue-800 text-[#cc262c] text-[2rem] pb-1 '>
                                    GOATFARM
                                </div> */}
                                <p className='oswald goatfarm-text flex flex-col pb-8'>
                                    <span className='flex mb-[-1rem] text-[1.75rem]'>

                                        Leading the industry since 1999.
                                    </span>
                                    <br />

                                    Goat farm has been leading the industry with innovation since 1999, and over the years our purpose has never changed. We have always been driven to unlock the greatest potential of every animal. The website looks better and more official with this paragraph right here. This is filler text why are you still reading.


                                </p>
                            </div>
                            <div className='flex flex-col max-w-[500px] border-y-[1px] border-[#000000] sponsorship-grid'>
                                <div className='oswald text-black pt-4'>
                                    PARTNERSHIPS
                                </div>
                                <div className="grid grid-cols-3 gap-4 mb-4    py-2">
                                    {gridItems.map((item, index) => (
                                        <motion.div key={index} className="border-[2px] border-[#cc262c] p-4 text-center aspect-square items-center justify-center flex cursor-pointer max-w-[150px] bg-white" whileHover={{ scale: 1.02 }} onClick={()=>launchLink(item.link)}>
                                            <img src={item.img} className='w-3/4 grid-imgs' />
                                        </motion.div>
                                    ))}
                                </div>
                                <div className='oswald text-black pt-2 pb-2' >
                                    PRESS
                                </div>
                                <motion.div className='flex items-center pb-4 cursor-pointer' onClick={()=>launchLink('https://www.hitc.com/en-gb/2023/03/16/where-to-buy-bad-bunnys-pokemon-hat-from-carpool-karaoke/')} whileHover={{x:2}}>

                                    <FaLink className='text-[#cc262c] text-[1.5rem] mr-4'/>
                                    <div className='flex oswald text-[#cc262c]'>
                                    BAD BUNNY WEARS POKEMON HAT ON CARPOOOL KARAOKE
                                    </div>
                                </motion.div>

                            </div>
                            <div className='flex about-social-links'>
                                <motion.div className='flex text-[#cc262c] items-center oswald py-2 px-2 cursor-pointer' onClick={() => launchLink('https://www.instagram.com/goatfarm.life?igsh=MThzcmEzc2FuazNiNQ==')} whileHover={{ scale: 1.02 }}>

                                    <BsInstagram className='flex  text-[1.5rem] mr-2' />
                                    GOATFARM
                                </motion.div>
                                <motion.div className='flex text-[#cc262c] items-center oswald py-2 px-2 cursor-pointer' onClick={() => launchLink('https://discord.gg/Wmksj4URSS')} whileHover={{ scale: 1.02 }}>

                                    <IoLogoDiscord className='flex  text-[1.5rem] mr-2' />
                                    GOATFARM
                                </motion.div>
                                <motion.div className='flex text-[#cc262c] items-center oswald py-2 px-2 cursor-pointer' onClick={() => launchLink('https://www.instagram.com/slumpykev?igsh=MWdrcGswMHJ1Nmo2Nw==')} whileHover={{ scale: 1.02 }}>

                                    <BsInstagram className='flex  text-[1.5rem] mr-2' />
                                    SLUMPYKEV
                                </motion.div>
                                <motion.div className='flex text-[#cc262c] items-center oswald py-2 px-2 cursor-pointer' onClick={() => launchLink('https://twitter.com/slumpykev')} whileHover={{ scale: 1.02 }}>

                                    <BsTwitter className='flex  text-[1.5rem] mr-2' />
                                    SLUMPYKEV
                                </motion.div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </>
    )
}

export default About