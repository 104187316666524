import logo from './logo.svg';
import './App.css';
import Main from './Components/Main';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Toaster />
      <BrowserRouter>

        <Main />
      </BrowserRouter>
    </div>
  );
}

export default App;
