
import React, {useRef, Suspense, useState, useEffect} from 'react'

import { Canvas, useLoader } from "@react-three/fiber"
import { Environment, OrbitControls } from "@react-three/drei"
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader'
import * as THREE from 'three';

const Model = (props) =>{
  try {
    const obj = useLoader(OBJLoader, "newGoat40.obj", (loader) => {
      // Configure loader settings (optional)
      // loader.setPath('path/to/your/models/'); // Example of setting a custom path
    });

    obj.traverse(child => {
      if (child.isMesh) {
        child.material = new THREE.MeshStandardMaterial({
          color: '#cc262c',
          metalness: 1,
          roughness: 0.2,
          opacity: 0.1
        });
      }
    });

    return <primitive object={obj} scale={props.screenWidth > 700 ? 1.3 : 0.85} />;
  } catch (error) {
    console.error("Error loading OBJ model:", error);
    return null; // or handle the error in a different way
  }
}
const GoatOBJ = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  //console.log(props)
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    // Set initial screen size
    handleResize();

    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <Canvas>
        <Suspense fallback={null}>
          <pointLight position={[20,5,5]} intensity={100} color={0xffffffff}/>
          <Model screenWidth={screenWidth}/>
          <OrbitControls enablePan={false} enableZoom={false}/>
          <Environment preset="sunset"  />
        </Suspense>
      </Canvas>
  )
}

export default GoatOBJ