import React, { useEffect } from 'react';

const KlaviyoIntegration = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=XhddDw';
    document.body.appendChild(script);

    return () => {
      // Cleanup: Remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return null; // KlaviyoIntegration doesn't render any DOM elements
};

export default KlaviyoIntegration;
