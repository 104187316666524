import React, { useEffect, useState } from 'react'
import client from '../shopifyClient'
import toast from 'react-hot-toast'
const ProductView = ({ id, setCartItems, addToCart }) => {
    //console.log('Id received: ' + id)
    const [product, setProduct] = useState()
    const [imageIndex, setImageIndex] = useState(0)
    const [checkoutId, setCheckoutId] = useState()
    const [variantIndexSelected, setVariantIndexSelected] = useState(0);
    const fetchProduct = async (handle) => {
        //console.log('fetching product')
        await client.product.fetchByHandle(handle).then((fetchedProduct) => {
            console.log(fetchedProduct)
            //console.log(fetchedProduct)
            setProduct(fetchedProduct);
        });
    }
    
    // console.log('Screen printed Boo jeans./nSize up for baggier fit')
    useEffect(() => {
        if (id) {
            console.log('id preseent ')
            console.log(id)
            fetchProduct(id)
        }
        if (product) {
            // console.log(product.description.split(' /n '))
            console.log(product)
            console.log(product?.description)
    console.log(product.description.includes('\n'))
    console.log(product.description.split('\n'))
        }

        const storedCheckoutId = localStorage.getItem('checkoutId');

        const fetchCheckout = async (checkoutId) => {
            try {
                const checkout = await client.checkout.fetch(checkoutId);
                if (checkout.completedAt) {
                    // Checkout is completed, create a new one
                    createNewCheckout();
                } else {
                    //console.log('Checkout not completed')
                    //console.log(checkout)
                    //console.log(checkout.lineItems)
                    // Use the existing, ongoing checkout
                    setCheckoutId(checkout.id);
                    setCartItems(checkout.lineItems);
                }
            } catch (error) {
                // Handle any errors, e.g., checkout not found
                console.error('Error fetching checkout:', error);
                createNewCheckout();
            }
        };

        const createNewCheckout = async () => {
            try {
                const newCheckout = await client.checkout.create();
                setCheckoutId(newCheckout.id);
                localStorage.setItem('checkoutId', newCheckout.id);
                setCartItems([]);
            } catch (error) {
                console.error('Error creating a new checkout:', error);
            }
        };

        if (storedCheckoutId) {
            fetchCheckout(storedCheckoutId);
        } else {
            createNewCheckout();
        }
    }, [id])
    return (
        <div className='flex w-full h-[100vh] justify-center py-8 product-outer overflow-x-clip'>
            <div className='flex w-[80%] items-center justify-center bg-[#efefef] rounded-[1rem] product-container'>
                {product &&
                    <>

                        <div className=' w-1/2  !aspect-square flex flex-col product-image-container'>


                            <img src={product.images[imageIndex].src} className='w-full mt-[-2.5rem] product-img-main' />
                            <div className='h-[6rem] py-2 w-full  flex overflow-x-scroll justify-center product-imgs-container'>
                                {product.images.map((image, i) => {
                                    return (
                                        <>
                                            <img src={image.src} className='h-full mx-2 cursor-pointer product-mini-image' onClick={() => setImageIndex(i)} />

                                        </>
                                    )
                                })}
                            </div>

                        </div>
                        <div className='flex w-1/2 px-16 flex-col justify-start product-content'>
                            <div className='flex text-[2rem] text-[#000000] oswald text-start'>
                                {product.title}
                            </div>
                            <div className='flex text-[2rem] text-[#cc262c] oswald text-start'>
                                ${parseFloat(product.variants[0].price.amount).toFixed(2)}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} className='flex flex-col text-[1rem] text-[#000000] oswald text-start'>
                                {/* {product.descriptionHtml} */}
                            </div>
                            <div className='flex px-4 py-2 border-[1px] border-white oswal text-[0.75rem] rounded-[0.5rem] w-[132px] text-white bg-[#cc262c] mt-2 justify-center text-center'>
                                {product.variants[variantIndexSelected].available ?
                                    <>

                                        IN STOCK
                                    </>
                                    :
                                    <>

                                        OUT OF STOCK
                                    </>
                                }
                            </div>
                            {product &&
                                <>
                                    {product.variants.length > 1 &&
                                        <>

                                            <div className='flex flex-col w-full justify-center items-start mt-2'>
                                                <label htmlFor='variantSelect' className='mr-2 text-[1rem] text-[#000000] oswald'>Product Option:</label>
                                                <select
                                                    id='variantSelect'
                                                    className='border-[1px] border-[#000000] rounded-[0.5rem] py-1 px-4 text-[0.75rem] w-1/2 h-[40px]'
                                                    onChange={(e) => setVariantIndexSelected(parseInt(e.target.value))}
                                                    value={variantIndexSelected}
                                                >
                                                    {product.variants.map((variant, index) => (
                                                        <option key={variant.id} value={index}>{variant.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </>
                                    }
                                </>

                            }
                            {product.variants[variantIndexSelected].available === false ?
                                <>

                                    <div className={'flex bg-[#414040] rounded-[0.5rem] w-[300px] text-white py-4 justify-center text-center oswald  mt-8 cursor-not-allowed'}

                                        onClick={() => toast.error('Product Out of Stock!')}>
                                        Sold Out
                                    </div>
                                </>
                                :
                                <>
                                    <div className={'flex bg-black rounded-[0.5rem] w-[300px] text-white py-4 justify-center text-center oswald  mt-8 cursor-pointer add-to-cart-btn'}

                                        onClick={() => addToCart(product.variants[variantIndexSelected].id)}>
                                        Add To Cart
                                    </div>

                                </>
                            }
                            <div className='flex w-full border-t-[1rem] mt-4  border-[#cc262c]'></div>
                        </div>
                    </>

                }
            </div>

        </div>
    )
}

export default ProductView