import React, { useEffect, useState } from 'react';
import goat from '../imgs/DwarfNew.png'
import horseAndCow from '../imgs/horse-cow.webp'
import horseReal from '../imgs/HorseReal.png'
import cowReal from '../imgs/CowReal.png'
import axios from 'axios';
import toast from 'react-hot-toast';
const Contact = () => {
  const [url, setURL] = useState()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    const response = await axios.post( url.includes('http://localhost:8888')? 'http://localhost:8888' + '/.netlify/functions/contact' : 'https://remarkable-tulumba-b33234.netlify.app/.netlify/functions/contact', {name:formData.name, email:formData.email, message:formData.message}).then((res)=>{
      return(res.data)
    })

    console.log(response)

    if(response){
      if(response.message==='Contact data received successfully!'){
        toast.success('Contact form submitted successfully!')
      }
      else{
        toast.error('Contact form error. Please try again.')
      }
    }

    
    // Clear the form after submission
    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };
  useEffect(()=>{
    const currentUrl = window.location.href;
    setURL(currentUrl)
  },[])

  return (
    <div className='flex w-full  bg-[#ffffff] h-[100vh] justify-center items-center flex-col contact-outer'>
      {/* <img src={goat} className='w-[300px]' /> */}
      <div className='flex w-full contact-shell'>
        <div className='flex w-1/3 justify-center items-center contact-animal'>

          <img src={horseReal} className='h-[300px]' />
        </div>
        <div className=' w-full h-[15vh] justify-center items-center hidden contact-animal-mobile px-2'>

          <img src={horseAndCow} className='h-full ml-4' />
        </div>

        <form onSubmit={handleSubmit} className="max-w-md mx-auto bg-white p-8 rounded-md shadow-md h-auto flex flex-col w-1/2 text-start contact-form-shell">
          <div className='oswald text-[#cc262c] text-[2rem]  mb-4'>
            CONTACT
          </div>
          <div className='flex border-t-[8px]  border-[#cc262c] mb-8' />
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-600 text-sm font-semibold mb-2 oswald">
              NAME:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-[#cc262c]"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-600 text-sm font-semibold mb-2 oswald">
              EMAIL:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:[#cc262c]"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="message" className="block text-gray-600 text-sm font-semibold mb-2 oswald ">
              MESSAGE:
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:[#cc262c]"
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full bg-black text-white py-2 px-4 rounded-md hover:bg-[#0000008a] focus:outline-none focus:[#cc262c]] oswald"
          >
            Submit
          </button>
        </form>
        <div className='flex w-1/3 justify-center items-center contact-animal'>

          <img src={cowReal} className='h-[300px]' />
        </div>
      </div>
    </div>
  );
};

export default Contact;
