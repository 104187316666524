import React, {useState, useEffect} from 'react'
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import NavBar from './NavBar'
import Home from './Home'
import Contact from './Contact';
import Shop from './Shop';
import Footer from './Footer';
import About from './About';
import ProductView from './ProductView';

const Main = () => {
  const [scrollY, setScrollY] = useState(0);
  const location = useLocation()
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    
    <div className='w-full '>
      <NavBar scrollY={scrollY}/>
      <div className='flex w-full flex-col' style={{paddingTop: (scrollY<800 && location.pathname==='/')?'0px':'73px'}}>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shop/:collectionId/*" element={<Shop />} />
          <Route path="/products/:productId" element={<Shop />} />
          <Route path="/goatfarm" element={<About />} />
          
        </Routes>
      </div>
      <Footer/>
    </div>
 
  )
}

export default Main