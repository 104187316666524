import React, { useState } from 'react'
import horseIcon from '../imgs/HorsePath.png'
import horseIconWhite from '../imgs/HorsePathWhite.png'
import NavGridItem from './NavGridItem'
const NavGrid = () => {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <div className='flex w-[80%] justify-center nav-grid-container'>

            <div className="grid grid-cols-3 gap-4 w-full h-[20vh] max-w-[900px] nav-grid-container-inner">
                <NavGridItem link={'Shop'}/>
                <NavGridItem link={'Contact'}/>
                <NavGridItem link={'goatfarm'}/>
                {/* <NavGridItem link={'Instagram'}/> */}

            </div>
        </div>
    )
}

export default NavGrid