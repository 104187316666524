import React, { useEffect, useState } from 'react'
import horseCowImage from '../imgs/DwarfNew.png'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import client from '../shopifyClient'
import toast from 'react-hot-toast';
import ProductView from './ProductView';
import GoatOBJ from './GoatOBJ';
import Spline from '@splinetool/react-spline';

const Shop = (props) => {
    let { collectionId, productId } = useParams();
    const [products, setProducts] = useState([]);
    const [checkoutId, setCheckoutId] = useState(localStorage.getItem('checkoutId') || null);
    const [cartItems, setCartItems] = useState([]);
    const [featuredProduct, setFeaturedProduct] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()
    const addToCart = (variantId) => {
        const lineItemsToAdd = [{ variantId, quantity: 1 }];
        client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
            console.log('Product added to cart', checkout.lineItems);
            toast.success('Product added to your cart !')
        });
    };

    const fetchProductsFromCollection = async (collectionIdOrHandle) => {
        try {
            // Fetch collection by ID or handle
            const collection = await client.collection.fetchWithProducts(collectionIdOrHandle, { productsFirst: 200 }); // Adjust productsFirst for the number of products
            console.log(collection)
            setLoading(true)
            setProducts(collection.products)
            await fetchFeaturedCollection();
            setLoading(false)
            return collection.products;
        } catch (error) {
            console.error("Error fetching collection and products:", error);
            return [];
        }
    };

    async function fetchFeaturedCollection() {
        const collId = '297790701765'
        const globalId = `gid://shopify/Collection/${collId}`;
        const encodedId = btoa(globalId);

        try {
            // Fetch collection by ID or handle
            const collection = await client.collection.fetchWithProducts(encodedId, { productsFirst: 10 }); // Adjust productsFirst for the number of products
            //console.log(collection)
            //console.log(collection.products)
            if (collection.products.length > 0) {
                setFeaturedProduct(collection.products[0])
            }
        } catch (error) {
            console.error("Error fetching collection and products:", error);
            return [];
        }

    }

    useEffect(() => {
        console.log('running useffect')

        const collectionMapping = {
            'shop-all':'235128979653',
            'clothing':'309645344965',
            'hats':'309645377733',
            'accessories':'309645443269'
        }

        console.log('logging collectionId')
        console.log(collectionId)
        const collId = collectionMapping[collectionId];
        console.log('logging collId')
        console.log(collId)
        // const collId = '232293761221'
        const globalId = `gid://shopify/Collection/${collId}`;
        const encodedId = btoa(globalId);
        console.log(location.pathname)
        if(!location.pathname.includes('/products')){
            fetchProductsFromCollection(encodedId);
        }
        else{
            console.log('Includes target pathanme')
        }
        const storedCheckoutId = localStorage.getItem('checkoutId');
        console.log(storedCheckoutId)
        const fetchCheckout = async (checkoutId) => {
            try {
                const checkout = await client.checkout.fetch(checkoutId);
                if (checkout.completedAt) {
                    // Checkout is completed, create a new one
                    createNewCheckout();
                } else {
                    console.log('Checkout not completed')
                    console.log(checkout)
                    console.log(checkout.lineItems)
                    // Use the existing, ongoing checkout
                    setCheckoutId(checkout.id);
                    setCartItems(checkout.lineItems);
                }
            } catch (error) {
                // Handle any errors, e.g., checkout not found
                console.error('Error fetching checkout:', error);
                createNewCheckout();
            }
        };

        const createNewCheckout = async () => {
            try {
                const newCheckout = await client.checkout.create();
                setCheckoutId(newCheckout.id);
                localStorage.setItem('checkoutId', newCheckout.id);
                setCartItems([]);
            } catch (error) {
                console.error('Error creating a new checkout:', error);
            }
        };

        if (storedCheckoutId) {
            fetchCheckout(storedCheckoutId);
        } else {
            createNewCheckout();
        }
    }, [collectionId, location]);
    // console.log(products)
    return (
        <>

            {location.pathname.includes('/shop/') ?

                <div className='w-full h-auto bg-[#ebebeb] items-center shop-container min-h-[100vh]'>
                    {/* <div className='flex w-full bg-white  items-center justify-center'>
                        <div className='flex flex-col pb-[4rem] my-4 items-center'>
                            <span className='neue-300 text-[#cc262c] text-[3.5rem] z-[50]'>
                                SHOP FOR
                            </span>
                            <span className='neue-800 text-[#cc262c] text-[4rem] leading-[40px] z-[50]'>
                                GREATNESS
                            </span>
                            <img className='w-[300px]' src={horseCowImage} />
                        </div>
                    </div> */}
                    {!featuredProduct &&
                        <div className='flex h-[30vh] shop-goat-container items-center mt-4'>
                            <GoatOBJ scale={1.3} />
                        </div>
                    }
                    <div className='masonry-grid grid  gap-4 py-8'>
                        {loading === false &&
                            <>

                                <>
                                    {products.map((product) => {
                                        let multiVariantStockCheck;
                                        if(product.variants.length > 1){
                                            for(var i=0; i<product.variants.length;i++){
                                                if(product.variants[i].available===true){
                                                    multiVariantStockCheck=true;
                                                }
                                            }
                                            // console.log(product.variants)
                                        }
                                        return (
                                            <div key={product.id} className='masonry-grid-item bg-white rounded-[1rem] border-[1px] border-[#ddd] px-4 py-4 flex flex-col items-center' onClick={() => navigate(`/products/${product.handle}`)}>
                                                <img src={product.images[0].src} className='w-full cursor-pointer max-w-[180px]' />
                                                <h2 className='neue-800 mt-4'>${parseFloat(product.variants[0].price.amount).toFixed(2)}</h2>
                                                <h2 className='oswald'>{product.title}</h2>
                                                <div className='flex w-full justify-center items-center'>

                                                    {product.variants.length > 1 ?
                                                        <>
                                                            {multiVariantStockCheck===true ?
                                                                <div className='flex border-[1px] border-[#ffffff] text-[#ffffff] bg-[#cc262c] hover:border-[#cc262c] hover:text-[#ffffff] hover:bg-[#cc262c] px-4 py-2  my-4 neue-300 cursor-pointer rounded-[0.5rem] ' onClick={() => navigate(`/products/${product.handle}`)}>

                                                                    VIEW PRODUCT

                                                                </div>
                                                                :
                                                                <div className='flex border-[1px] border-[#ffffff] text-[#ffffff] bg-[#747373]  px-4 py-2  my-4 neue-300 cursor-not-allowed rounded-[0.5rem] ' >

                                                                    Sold Out

                                                                </div>
                                                            }

                                                        </>
                                                        :
                                                        <>

                                                            {product.variants[0].available === true ?
                                                                <div className='flex border-[1px] border-[#ffffff] text-[#ffffff] bg-[#cc262c] hover:border-[#cc262c] hover:text-[#ffffff] hover:bg-[#cc262c] px-4 py-2  my-4 neue-300 cursor-pointer rounded-[0.5rem] ' onClick={() => addToCart(product.variants[0].id)}>

                                                                    ADD TO CART

                                                                </div>
                                                                :
                                                                <div className='flex border-[1px] border-[#ffffff] text-[#ffffff] bg-[#747373]  px-4 py-2  my-4 neue-300 cursor-not-allowed rounded-[0.5rem] ' >

                                                                    Sold Out

                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </>

                            </>
                        }
                    </div>

                </div>
                :
                <div className='w-full h-auto bg-[#ebebeb]'>
                    <ProductView id={productId} setCartItems={setCartItems} addToCart={addToCart} />
                </div>
            }
        </>
    )
}

export default Shop
