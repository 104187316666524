import React, { useState, useEffect } from 'react'
import client from '../shopifyClient'
import { RiShoppingCart2Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import toast from 'react-hot-toast';
const Cart = (props) => {
    const [cartItems, setCartItems] = useState([]);
    const [checkoutId, setCheckoutId] = useState()
    const [cartTotal, setCartTotal] = useState(0)
    async function cartCheckout() {
        try {
            const checkout = await client.checkout.fetch(checkoutId);
            if (checkout.webUrl) {
                console.log(checkout.webUrl)
                if(window){                
                    setTimeout(() => {
                        window.open(checkout.webUrl, '_blank').focus();
                    })
                }
                else{
                    toast.error('Error: No window object present in DOM')
                    console.log('No window')
                }
            }
        } catch (error) {
            console.error("Error fetching checkout URL:", error);
            toast.error(error.message)
            return null;
        }
    }
    const removeFromCart = async (lineItem) =>{
        const updatedCheckout = await client.checkout.removeLineItems(checkoutId, [lineItem.id]);
        await fetchCheckout(checkoutId)
        toast.success('Removed item from your cart !')
    }

    const fetchCheckout = async (checkoutId) => {
        try {
            const checkout = await client.checkout.fetch(checkoutId);
            if (checkout.completedAt) {
                // Checkout is completed, create a new one
                createNewCheckout();
            } else {
                // Use the existing, ongoing checkout
                setCartTotal(checkout.totalPrice)
                setCheckoutId(checkout.id);
                setCartItems(checkout.lineItems);
            }
        } catch (error) {
            // Handle any errors, e.g., checkout not found
            console.error('Error fetching checkout:', error);
            createNewCheckout();
        }
    };

    const createNewCheckout = async () => {
        try {
            const newCheckout = await client.checkout.create();
            setCheckoutId(newCheckout.id);
            localStorage.setItem('checkoutId', newCheckout.id);
            setCartItems([]);
        } catch (error) {
            console.error('Error creating a new checkout:', error);
        }
    };
    useEffect(() => {
        const storedCheckoutId = localStorage.getItem('checkoutId');

        if (storedCheckoutId) {
            fetchCheckout(storedCheckoutId);
        } else {
            createNewCheckout();
        }
    }, []);
    return (
        <div className='w-[500px] pt-8 pb-4 h-full bg-[#181818] !text-white  relative  !z-[1000]  flex  flex-col border-l-[1px] border-[#ffffff2a] rounded-l-[1rem] shadow-lg' onClick={props.handleCartModalClick}>
            <div className='flex w-full flex-1 flex-col pl-4 pb-4 h-full justify-between cart-container'>
                <div className='flex flex-col h-[80%] cart-80'>
                    <div className='flex w-full justify-between neue-600 mb-4 '>
                        <div className='flex items-center'>
                            <RiShoppingCart2Fill className='mr-2 text-[#ffffff8a] text-[1.5rem] cursor-pointer' />
                            Your Cart
                        </div>
                        <IoMdClose className='mr-8 text-[#ffffff8a] text-[1.5rem] cursor-pointer' onClick={() => props.setCartPopped(false)} />
                    </div>
                    <div className='flex w-full flex-col h-full overflow-auto px-8 border-[1px] rounded-[0.5rem] border-[#ffffff2a] bg-[#1f1f1f]'>

                        {cartItems.map((item) => {
                            console.log(item)
                            return (
                                <>
                                    <div className='flex w-full  h-[100px] border-b-[1px] border-[#ffffff1a] py-4'>
                                        <img src={item.variant.image.src} className='flex h-full aspect-square rounded-[0.5rem]' />
                                        <div className='flex flex-col w-full items-start h-full justify-center'>

                                            <div className='flex flex-col text-[0.65rem] ml-4 oswald'>
                                                {item.title}
                                            </div>
                                            <div className='flex flex-col text-[0.75rem] oswald ml-4'>
                                                ${item.variant.price.amount}
                                            </div>
                                            <div className='flex flex-col text-[0.5rem] oswald ml-4 mt-2 underline underline-offset-2 cursor-pointer' onClick={()=>removeFromCart(item)}>
                                                REMOVE
                                            </div>
                                        </div>


                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className='flex w-full items-center justify-center flex-col px-8  mt-2 h-[20%]'>
                    <div className='flex  oswald w-full justify-start'>
                        Cart Total:
                        <div className='flex items-center ml-2'>

                            {cartTotal.currencyCode === 'USD' &&
                                <>
                                    <span className='!neue-600'>$</span>
                                </>
                            }
                            {parseFloat(cartTotal.amount).toLocaleString()}
                        </div>
                    </div>
                    <div className='flex text-[0.5rem] w-full justify-start '>
                        *Shipping and Taxes calculated at checkout
                    </div>
                    <div className='flex w-full  items-center justify-center mt-4'>
                        {cartItems.length>0 ?
                        
                            <div className='flex bg-[#000000] text-white  px-8 py-2   rounded-[0.5rem] cursor-pointer z-[100]' onClick={cartCheckout}>
                                Checkout
                            </div>
                            :
                            <div className='flex bg-[#535353] text-white  px-8 py-2   rounded-[0.5rem] cursor-not-allowed' >
                                Your Cart Is Empty
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart