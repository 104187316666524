import React, { useEffect } from 'react'
import farmLoader from '../../src/lotties/farmLoader.json'
import Lottie from 'lottie-react';
import { CiStreamOn } from "react-icons/ci";

import goatFarmLogo from "../imgs/GOATFARM_without_c_white_430x.avif"

const Stream = ({ videoSource, setLoadingFinished, loadingFinished, videoIndex, videos }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            // After 3 seconds, update the state
            setLoadingFinished(true);
        }, 8000);

        return () => {
            clearTimeout(timer)
        }
    }, [videoSource])

    return (
        <>
            {loadingFinished === false &&
                <>

                    <div className=' absolute flex w-[840px] h-[457px] bg-black items-center justify-center stream-loading-container flex-col'>
                        <Lottie animationData={farmLoader} className='w-1/2' />
                        <img src={goatFarmLogo} className='w-1/4 mt-4 animate-pulse' />
                    </div>

                </>

            }



            <>
                
                {/* <CiStreamOn className='absolute  text-[#cc262c] !text-[4rem] animate-pulse  mt-[-16rem] ml-[25%]'/> */}
                {/* <Stream videoSource={videoSource} setLoadingFinished={setLoadingFinished} /> */}
                <iframe width="840" height="auto" src={videos[videoIndex] + "&amp;autoplay=1&amp;mute=1"} id="mobile-iframe" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" className=' hidden'></iframe>

                <iframe width="840" height="457" src={videos[videoIndex] + "&amp;autoplay=1&amp;mute=1"} id="youtube-iframe" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" className=''></iframe>
            </>
        </>
    )
}

export default Stream