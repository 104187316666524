import React from 'react'
import ninjaImg from '../imgs/ninja-black.webp';
const Footer = () => {
    return (
        <div className='w-full flex items-center justify-center text-[#0000008a] bg-[#ffffff] h-[10vh] flex-col'>
            {/* <img src={ninjaImg} className='h-[80%] max-h-[250px]' alt="Ninja" /> */}
            <div>
                Copyright © 2023, Goat farm
            </div>
        </div>
    )
}

export default Footer